import React from 'react';
import Styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import TestimonialCard from '../Testimonials/TestimonialCard'

const Section = Styled.div`
  min-height:200px;
  background-color:#fff;
  text-align:center;
  padding-top:30px; 
  width:100%;
  padding-bottom:40px; 

  .section-header__title {
    text-align: left; 
    color: #fd6e0d;
    font-size: 22px;
    font-weight: 700;
    line-height: 1.2;
    margin: 0 0 7.5px;
}

`;

const SingelCardWrapper = Styled.div`
padding: 0px 20px;    
outline: none;
background-color:#fff;

`; 

const TestimonialCart = ({ data, page}) => {
    // console.log('BuyCardsList', data)
    let finalData = data?.schema_json;
    let content = {
      cards: [
          {
              id: 1,
              description: '<strong>This was my first 5 day.</strong> <p>I was amazed about how I was rarely hungry and how much energy I had. Highly recommend!!</p><strong>--</strong>Michele Kehrer',
          },
          {
              id: 2,
              description: '<strong>The best fasting product out there.</strong><p>Very healthy, I felt renewed and ready to continue my journey to a healthier me.</p><strong>--</strong>Monica Monroy & Jonathan Garcia',
          },
          {
              id: 3,
              description: '<strong>Great diet.</strong> <p>Second time. Got easier. And the results are amazing - lost 4kg (9-10 lbs) in 5 days and I feel great.</p><strong>--</strong>Elad Shoushan',
          },
      ],
      ...finalData,
    };
    const cards = content?.cards?.map((card) => {
      return (
        <SingelCardWrapper>
            <TestimonialCard
            key={card.id}
            description={card.description}
            />
        </SingelCardWrapper>
      );
    });

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows:false,
    autoplay: true,
    autoplaySpeed: 5000, 
    // responsive: [
    //   {
    //     breakpoint: 990,
    //     settings: {
    //       slidesToShow: 1,
    //       slidesToScroll:2,
    //       infinite: true,
    //     }
    //   }, 
    //   {
    //     breakpoint: 680,
    //     settings: {
    //       slidesToShow: 1,
    //       slidesToScroll: 1,
    //       autoplay: true,
    //       autoplaySpeed: 2000, 
    //     } 
    //   }
    //   // You can unslick at a given breakpoint now by adding:
    //   // settings: "unslick"
    //   // instead of a settings object
    // ]
  };
  return (
    <>
      <Section className="px-5">
        <h3 className="section-header__title">Latest Reviews</h3>
        <div>
          <Slider {...settings} style={{ width: '100%' }}>
              {cards}
          </Slider>
        </div>
      </Section>
    </>
  );
}
 
export default TestimonialCart;
